
<script type="text/babel">
import { validationMixin } from 'vuelidate'
import { async } from 'q'
const { required, email } = require('vuelidate/lib/validators')

export default {
  mixins: [validationMixin],
  data () {
    return {
      formData: {
        email: ''
      },
      isSent: false,
      isLoading: false,
      isRegisteredUser: false
    }
  },
  validations: {
    formData: {
      email: {
        email,
        required
      }
    }
  },
  methods: {

    async validateBeforeSubmit (e) {
      this.$v.formData.$touch()

      if (await this.checkMail() === false) {
        toastr['error'](this.$t('validation.email_does_not_exist'))
        return
      }
      if (!this.$v.formData.$invalid) {
        try {
          this.isLoading = true
          let res = await axios.post('/api/auth/password/email', this.formData)

          if (res.data) {
            toastr['success']('Mail sent successfuly!', 'Success')
          }

          this.isSent = true
          this.isLoading = false
        } catch (err) {
          if (err.response && err.response.status === 403) {
            toastr['error'](err.response.data, 'Error')
          }
        }
      }
    },
    async checkMail () {
      let response = await window.axios.post('/api/is-registered', this.formData)
      return response.data
    }
  },
  created: function () {
    let test=decodeURIComponent(this.$route.query.email);
    if(localStorage.getItem('email') == test){
        localStorage.clear();
        window.location.href = "http://localhost:3000/";
    }
 
    /**
     * End of introduction by Abhishek Sharma
     */
  }
}
</script>
